import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "./MegaMenu.css";
import { URL } from "../../url/url";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const MegaMenu = () => {
  const [primaryMenu, setMenu] = useState([])  
  const [subchildMenu, setSubChild] = useState([]) 

  const getMenu = () => {
    
    fetch(`${URL}/api/primary_menu`)
        .then(res => res.json())
        .then(
          (res) => {                    
            const apiRest = res?.data; 
            setMenu(apiRest); 
          },
          (error) => {
            setMenu([]);
          }
        )
  }

  useEffect(() => {
    getMenu()
  }, [])


  return (
    <div className="header-links">
      <Container>
        <ul className="header-links-container">
          {primaryMenu && primaryMenu.map((element, index) => {
            let menu_key =`menu${index}`; //medicine_child_categories
            let child = element.medicine_child_categories; 
              return(
                  <li className="header-links-item" key={menu_key}>
                    <div className="header-childrenItem-parent">
                      <Link to={element.cat_url}>
                        <span className="header-childrenItem-link-text parentDropDownLinkText">
                        {element.medicine_category}
                        {child.length > 0 && <Icon.ChevronDown size={15} /> }
                        </span>
                      </Link>
                      <i className="fa fa-angle-down drop-icon"></i>
                    </div>   
                    {child.length > 0 &&
                      <div className={`header-childrenItem-child-category-links ${
                        index > 2
                          ? "header-childrenItem-child-category-linksRight0"
                          : ""
                      }`}>
                        <ul className="header-childrenItem-child-list">

                          {(child.length > 0) && child.map((el, index) => {  
                            const subchild = el.child;    
                            const sub_key =`submenu${index}`; //medicine_child_categories                 
                            return(
                            
                                <li key={sub_key}>
                                  <Link to={el.cat_url} className="childItem-level-2 customeChildItemLevel">
                                    <span className="header-childrenItem-link-text childDropDownlinkMegaMenu">
                                    {el.child_name}
                                    </span>
                                  </Link>
                                  {subchild.length > 0 &&
                                    <ul className="subchild-list">
                                      {(subchild.length > 0) && subchild.map((val, index) => {
                                        return(
                                          <li key={val.id} > <Link to={val.cat_url} className="childItem-level-3">{val.child_name} </Link></li>
                                        )
                                      })}
                                    </ul>
                                  }
                                </li>  
                            )
                          })}
                        </ul>
                      </div>
                    }
                  </li>                           
              )}          
            )}          
        </ul>
      </Container>
    </div>
  );
};

export default MegaMenu;
