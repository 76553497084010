import { URL } from "../url/url";
import {
  COMPLETE_REGISTER,
  CREATE_RATING,
  ENQUIRY_DELETE_PROFILE,
  GET_ALL_COUPON,
  GET_CITY_STATE,
  GET_HOME_PAGE_BANNER,
  GET_MY_MEDICINE,
  GET_ORDER_DETAILS,
  GET_ORDER_LIST,
  GET_OVERVIEW,
  GET_PATIENT_DETAILS,
  GET_PRIMARY_MENU,
  GET_PRODUCT_TAGS,
  GET_RATING,
  GET_SORT_DATA,
  GET_VARIATION_IMAGE,
  INQUIRITY_CONTACT,
  INQUIRITY_CREATE,
  ORDER_THANKS,
  OTP_LOGIN,
  PATIENT_PURCHASE,
  PAYNOW_API,
  RECENT_SEARCH_PRODUCT,
  SEARCH_DATA,
  SHIPPING_CHARGE,
  SIGNUP,
  SIGN_IN_WEB,
  SIMILAR_SEARCH_PRODUCT,
  SINGLE_USE_COUPON,
  TRACK_ORDER,
  UPDATE_USER_PROFILE,
  UPLOAD_PRESCRIPTION,
  VERIFY_OTP_LOGIN,
  VERIFY_OTP_SIGNUP,
} from "./Routes";
import axios from "axios";

axios.defaults.baseURL = URL;

export const inquirityCreateApi = async (data) => {
  try {
    const response = await axios.post(INQUIRITY_CREATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addContactApi = async (data) => {
  try {
    const response = await axios.post(INQUIRITY_CONTACT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const payNowApi = async (data) => {
  try {
    const response = await axios.post(PAYNOW_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPatientPurchase = async (data) => {
  try {
    const response = await axios.get(
      PATIENT_PURCHASE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getRecentSearchApi = async (data) => {
  try {
    const response = await axios.get(
      RECENT_SEARCH_PRODUCT,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSimilarSearchMedicineApi = async (data) => {
  try {
    const response = await axios.get(
      SIMILAR_SEARCH_PRODUCT,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOrderThanksApi = async (data) => {
  try {
    const response = await axios.get(
      ORDER_THANKS,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getShippingCharge = async (data) => {
  try {
    const response = await axios.get(
      SHIPPING_CHARGE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getCouponApi = async (id) => {
  try {
    const response = await axios.get(
      GET_ALL_COUPON,
      {
        params: id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const singleUseCouponApi = async (data) => {
  try {
    const response = await axios.post(SINGLE_USE_COUPON, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const completeRegisterApi = async (data) => {
  try {
    const response = await axios.post(COMPLETE_REGISTER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const createReviewApi = async (data) => {
  try {
    const response = await axios.post(CREATE_RATING, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getReviewApi = async (data) => {
  try {
    const response = await axios.get(
      GET_RATING,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStateAndCityApi = async (data) => {
  try {
    const response = await axios.post(GET_CITY_STATE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOrderListApi = async (data) => {
  try {
    const response = await axios.get(
      GET_ORDER_LIST,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPatientDetailsApi = async (data) => {
  try {
    const response = await axios.get(
      GET_PATIENT_DETAILS,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPrimaryMenuApi = async () => {
  try {
    const response = await axios.get(GET_PRIMARY_MENU, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getProductTagsApi = async (data) => {
  try {
    const response = await axios.get(
      GET_PRODUCT_TAGS,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const updateUserProfileApi = async (data) => {
  try {
    const response = await axios.post(UPDATE_USER_PROFILE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSortDataApi = async () => {
  try {
    const response = await axios.get(GET_SORT_DATA, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOrderDetailsApi = async (data) => {
  try {
    const response = await axios.get(
      GET_ORDER_DETAILS,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getHomePageBannerApi = async () => {
  try {
    const response = await axios.get(GET_HOME_PAGE_BANNER, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const signupApi = async (data) => {
  try {
    const response = await axios.post(SIGNUP, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const uploadPrescriptionApi = async (data) => {
  try {
    const response = await axios.post(UPLOAD_PRESCRIPTION, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpLoginApi = async (data) => {
  try {
    const response = await axios.post(OTP_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const verifyOtpLoginApi = async (data) => {
  try {
    const response = await axios.post(VERIFY_OTP_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const verifyOtpSignupApi = async (data) => {
  try {
    const response = await axios.post(VERIFY_OTP_SIGNUP, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const SigninWebApi = async (data) => {
  try {
    const response = await axios.post(SIGN_IN_WEB, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOverviewApi = async (data) => {
  try {
    const response = await axios.get(
      GET_OVERVIEW,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOrderTrackApi = async (data) => {
  try {
    const response = await axios.get(
      TRACK_ORDER,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSearchDataApi = async (data) => {
  try {
    const response = await axios.get(
      SEARCH_DATA,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getVariationImageApi = async (data) => {
  try {
    const response = await axios.get(
      GET_VARIATION_IMAGE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getMyMedicineApi = async (data) => {
  try {
    const response = await axios.get(
      GET_MY_MEDICINE,
      {
        params: data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getEnquiryDeleteProfileApi = async (data) => {
  try {
    const response = await axios.post(
      ENQUIRY_DELETE_PROFILE, data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
