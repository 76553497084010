import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, URL } from "../../url/url";
import cartIcon from "../../image/cartheader.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";
import "react-datepicker/dist/react-datepicker.css";
import "../Header/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { useCart } from "../Context/CartContext";
import style from "../../Assets/layout.module.css";
import LoginModal from "../Common/LoginModal";
import OtpModal from "../Common/OtpModal";
import SignupModal from "../Common/SignupModal";
import SignUpOtp from "../Common/SignUpOtp";
import RegistrationModal from "../Common/RegistrationModal";
import {
  SigninWebApi,
  completeRegisterApi,
  getSearchDataApi,
  otpLoginApi,
  signupApi,
  verifyOtpLoginApi,
  verifyOtpSignupApi,
} from "../../services/Services";
import { useLocation } from "react-router-dom/dist";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LOGIN_SUCCESS, LOGOUT } from "../../actions/types";
import styles from "../../Assets/home.module.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { CrossIcon, SearchIcon } from "../../utils/Icons";

function Header({ AllCategoryData }) {
  const [signupPopupShow, setsignupPopupShow] = useState(false);
  const [loginPopupShow, setloginPopupShow] = useState(false);
  const [OTPVerifyPopupShow, setOTPVerifyPopupShow] = useState(false);
  const [RegisterCompletePopupShow, setRegisterCompletePopupShow] =
    useState(false);
  const [otplogin, setotplogin] = useState(false);
  const [OtploginPopupShow, setOtploginPopupShow] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [email, setemail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setdob] = useState(null);
  const [otp, setotp] = useState("");
  const dispatch = useDispatch();
  const [loginid, setloginid] = useState("");
  const [patientID, SetPatientID] = useState(0);
  const { isLoggedIn, setisLoggedIn } = useSelector((state) => state.auth);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const { cartItems } = useCart();

  let currDate = new Date("01-01-2001");
  const navigate = useNavigate();
  const location = useLocation();

  // header search start
  const handleChangeSelect = (item) => {
    setSelectedSearch(item);
    if (item?.length > 0) {
      navigate(`${item && item[0]?.link}`);
      setSearchValue("");
      setSelectedSearch([]);
    }
  };

  const handleInputChange = (e) => {
    setSearchValue(e);
  };

  const fetchSearchData = async (data) => {
    try {
      const result = await getSearchDataApi(data);
      if (result?.res?.status === 200) {
        const _Data = result?.res?.data?.map((item) => {
          return {
            label: item?.medicine_name_full,
            id: item?.id,
            link: item?.link,
          };
        });
        setSearchData(_Data);
      } else {
        setSearchData([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (searchValue) {
      const cleanFunction = setTimeout(() => {
        fetchSearchData({
          q: searchValue,
        });
      }, [500]);
      return () => clearTimeout(cleanFunction);
    } else {
      setSearchData([]);
    }
  }, [searchValue]);
  // header search end

  const handleLoginPopup = () => {
    setloginPopupShow(true);
    setsignupPopupShow(false);
    setRegisterCompletePopupShow(false);
  };
  const handleSignupPopup = () => {
    setloginPopupShow(false);
    setRegisterCompletePopupShow(false);
    setsignupPopupShow(true);
  };

  const handleOtp = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const result = await verifyOtpSignupApi({ mobile: Mobile, otp: otp });
      if (result?.res?.status === 200 || result?.res?.status === true) {
        setBtnLoading(false);
        toast.success(result?.res?.message);
        SetPatientID(result?.res?.signup?.id);
        setOTPVerifyPopupShow(false);
        setRegisterCompletePopupShow(true);
      } else {
        setBtnLoading(false);
        toast.error(result?.res?.message || result?.message);
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error(error?.message);
    }
  };

  const Logout = () => {
    const isLogout = window.confirm("Are you sure you want to logout?");
    if (isLogout) {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("loginDetail");
      navigate("/");
      dispatch({ type: LOGOUT });
      setExpanded(false);
    }
  };
  const handleSignInWeb = async (data) => {
    try {
      const result = await SigninWebApi({
        uid: data ? data : loginid,
        Mobile,
        otplogin,
      });
      if (result?.res?.status) {
        setBtnLoading(false);
        toast.success(result?.res?.message);
        localStorage.setItem("loginDetail", JSON.stringify(result?.res));
        localStorage.setItem("isLoggedIn", true);
        setOtploginPopupShow(false);
        setOTPVerifyPopupShow(false);
        dispatch({ type: LOGIN_SUCCESS, payload: { user: result?.res } });
      } else {
        setBtnLoading(false);
        toast.error(result?.res?.message || result?.message);
      }
    } catch (error) {
      toast.error(error?.message);
      setBtnLoading(false);
    }
  };
  const handleOtpLogin = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const result = await verifyOtpLoginApi({
        user_id: loginid,
        mobile: Mobile,
        otp: otplogin,
        otp_type: "login",
      });
      if (result?.res?.status === 200) {
        localStorage.setItem("loginDetail", JSON.stringify(result?.res?.data));
        localStorage.setItem("isLoggedIn", true);
        handleSignInWeb();
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        toast.error(result?.res?.message || result?.message);
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error(error?.message);
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setBtnLoading(true);
      const result = await signupApi({
        mobile: Mobile,
      });
      if (result?.res?.status === 200 || result?.res?.success) {
        setBtnLoading(false);
        toast.success(result?.res?.message);
        setOTPVerifyPopupShow(true);
        setloginPopupShow(false);
        setsignupPopupShow(false);
      } else {
        setBtnLoading(false);
        toast.error(result?.res?.message || result?.message);
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error(error?.message);
    }
  };
  const CompleteRegisters = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const sendData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: dob,
        mobile: Mobile,
        loginid: patientID,
      };
      const result = await completeRegisterApi(sendData);
      if (result?.res?.status || result?.res?.status === 200) {
        handleSignInWeb(result?.res?.data?.id);
        setOTPVerifyPopupShow(false);
        setRegisterCompletePopupShow(false);
        setFirstName("");
        setLastName("");
        setdob(null);
        setemail("");
        setBtnLoading(false);
      } else {
        toast.error(result?.res?.message || result?.message);
        setBtnLoading(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setBtnLoading(false);
    }
  };
  const loginPatient = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const result = await otpLoginApi({
        mobile: Mobile,
      });
      if (result?.res?.status === 200) {
        toast.success(result?.res?.message);
        setloginid(result?.res?.patient_detail?.user_id);
        setloginPopupShow(false);
        setOtploginPopupShow(true);
        setBtnLoading(false);
      } else {
        toast.error(result?.res?.message || result?.message);
        setBtnLoading(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setBtnLoading(false);
    }
  };
  const getemail = (e) => {
    let email = e.target.value;
    setemail(email);
  };

  const getdob = (date) => {
    let dt = moment(date).format("YYYY-MM-DD");
    setdob(dt);
  };

  const onChangeMobile = (e) => {
    let mobile = e.target.value;
    setMobile(mobile);
  };

  const getOtp = (e) => {
    let otp = e.target.value;
    setotp(otp);
  };

  const getotplogin = (e) => {
    let otplogin = e.target.value;
    setotplogin(otplogin);
  };
  if (localStorage.getItem("cart")) {
    let cart = JSON.parse(localStorage.getItem("cart"));
  }

  let loginDetail = [];
  if (localStorage.getItem("loginDetail")) {
    loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  }

  const closeToggleButton = () => {
    setExpanded(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("faq");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const filterOptions = (query, optionsObj) => {
    const optionsArray = optionsObj?.options;
    return optionsArray?.filter(option =>
      option.label.toLowerCase().includes(query?.label.toLowerCase())
    );
  };

  const logo = `${BASE_URL}/2023/05/logo.PNG`;

  return (
    <>
      <Navbar
        collapseOnSelect
        className={`${style.navbarHeaderMain} position-relative`}
        expand="lg"
        expanded={expanded}
      >
        <Container>
          <div className={style.topHeaderMain}>
            <Navbar.Brand>
              <Link to={"/"} className={style.NavbarBrand}>
                <LazyLoadImage
                  src={logo}
                  className="logoStyle"
                  text="mChemist Logo"
                />
              </Link>
            </Navbar.Brand>
            {location.pathname !== "/" && (
              <div className={style.SeachBox}>
                <Typeahead
                  id="11specilities_search"
                  onChange={handleChangeSelect}
                  onInputChange={handleInputChange}
                  options={searchData}
                  placeholder="Search medicine"
                  defaultInputValue={searchValue}
                  selected={selectedSearch}
                  filterBy={filterOptions}
                  style={{ zIndex: 1000 }}
                  className={`CustomeAutoComplete`}
                />
                <span className={style.searchIcon}>
                  <SearchIcon />
                </span>
              </div>
            )}
            <div className="CartIconShowOnMobile">
              <Link
                to="/basket"
                className="js-menu__open headerCartIconMain"
                data-menu="#main-nav"
              >
                <LazyLoadImage
                  src={cartIcon}
                  alt="cartIcon"
                  data-menu="#main-nav"
                  className="js-menu__open"
                />
                <Badge
                  className="js-menu__open cartCountLength"
                  data-menu="#main-nav"
                  // bg="danger"
                >
                  {cartItems?.length}
                </Badge>
              </Link>
            </div>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(expanded ? false : true)}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="OnlyShowOnMobile" onClick={closeToggleButton}>
                <CrossIcon />
              </div>
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavDropdown
                  className="customNavbarDropDown"
                  title="Home"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item>
                    <Link
                      onClick={closeToggleButton}
                      to={"/pages/how-to-shop-on-mchemist"}
                    >
                      How to shop on mchemist
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link
                      onClick={closeToggleButton}
                      to={"/pages/what-is-mchemist"}
                    >
                      What is mchemist?
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={closeToggleButton} to={"/pages/about-us"}>
                      About Us
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link onClick={closeToggleButton} to={"/pages/contact-us"}>
                      Contact Us
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link
                      onClick={closeToggleButton}
                      to={"/pages/testimonials"}
                    >
                      Testimonials
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                {isLoggedIn ? (
                  <>
                    <Nav.Item className="customNabItem">
                      <Nav.Link
                        as={Link}
                        to={"/mymedicine"}
                        className="noWrapText"
                        onClick={closeToggleButton}
                      >
                        My Medicine
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="customNabItem">
                    <Nav.Link as={Link} to={"/medication"}>
                      Medication
                    </Nav.Link>
                  </Nav.Item> */}
                    <Nav.Item className="customNabItem">
                      <Nav.Link onClick={Logout}>Logout</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="customNabItem">
                      <Nav.Link
                        as={Link}
                        to="/myaccount"
                        onClick={closeToggleButton}
                      >
                        My Account{" "}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="customNabItem">
                      <Nav.Link as="a" href="/#faq" onClick={handleClick}>
                        Need Help?
                      </Nav.Link>
                    </Nav.Item>
                  </>
                ) : (
                  <>
                    <Nav.Item className="customNabItem">
                      <Nav.Link
                        onClick={() => (
                          setloginPopupShow(true), setExpanded(false)
                        )}
                      >
                        Login
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="customNabItem">
                      <Nav.Link
                        onClick={() => (
                          setsignupPopupShow(true), setExpanded(false)
                        )}
                      >
                        Sign Up
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
                <Link
                  to="/basket"
                  className="js-menu__open headerCartIconMain DoNotCartIconShowOnMobile"
                  data-menu="#main-nav"
                >
                  <LazyLoadImage
                    src={cartIcon}
                    alt="cartIcon"
                    data-menu="#main-nav"
                    className="js-menu__open"
                  />
                  <Badge
                    className="js-menu__open cartCountLength"
                    data-menu="#main-nav"
                    // bg="danger"
                  >
                    {cartItems?.length}
                  </Badge>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
      <LoginModal
        setloginPopupShow={setloginPopupShow}
        loginPopupShow={loginPopupShow}
        setsignupPopupShow={setsignupPopupShow}
        onChangeMobile={onChangeMobile}
        loginPatient={loginPatient}
        btnLoading={btnLoading}
        handleSignupPopup={handleSignupPopup}
      />
      <OtpModal
        OtploginPopupShow={OtploginPopupShow}
        setOtploginPopupShow={setOtploginPopupShow}
        handleOtpLogin={handleOtpLogin}
        Mobile={Mobile}
        getotplogin={getotplogin}
        btnLoading={btnLoading}
        setloginPopupShow={setloginPopupShow}
      />
      <SignupModal
        signupPopupShow={signupPopupShow}
        setsignupPopupShow={setsignupPopupShow}
        onChangeMobile={onChangeMobile}
        isSubmitting={isSubmitting}
        handleRegister={handleRegister}
        btnLoading={btnLoading}
        handleLoginPopup={handleLoginPopup}
      />
      <SignUpOtp
        OTPVerifyPopupShow={OTPVerifyPopupShow}
        setOTPVerifyPopupShow={setOTPVerifyPopupShow}
        setsignupPopupShow={setsignupPopupShow}
        getOtp={getOtp}
        handleOtp={handleOtp}
        Mobile={Mobile}
        btnLoading={btnLoading}
        setloginPopupShow={setloginPopupShow}
      />
      <RegistrationModal
        RegisterCompletePopupShow={RegisterCompletePopupShow}
        setRegisterCompletePopupShow={setRegisterCompletePopupShow}
        getemail={getemail}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        getdob={getdob}
        CompleteRegisters={CompleteRegisters}
        setloginPopupShow={setloginPopupShow}
        currDate={currDate}
        dob={dob}
        setdob={setdob}
        handleLoginPopup={handleLoginPopup}
        error={error}
        btnLoading={btnLoading}
      />
    </>
  );
}

export default Header;
