export const INQUIRITY_CREATE = "/api/enquirty/create";
export const INQUIRITY_CONTACT = "/api/enquirty/contact_us";
export const PAYNOW_API = "/api/patient/paynow";
export const PATIENT_PURCHASE = "/api/patient/purchase";
export const SIGNUP = "/api/patient/signup";
export const OTP_LOGIN = "/api/patient/otplogin";
export const VERIFY_OTP_LOGIN = "/api/patient/verifyotplogin";
export const VERIFY_OTP_SIGNUP = "/api/patient/verifyotp";
export const SIGN_IN_WEB = "/api/patient/signinweb";
export const UPLOAD_PRESCRIPTION = "/api/patient/uploadPrescription";
export const RECENT_SEARCH_PRODUCT = "/api/recent";
export const SIMILAR_SEARCH_PRODUCT = "/api/similar";
export const ORDER_THANKS = "/api/orderthanks";
export const SHIPPING_CHARGE = "/api/shipping";
export const GET_ALL_COUPON = "/api/coupon";
export const SINGLE_USE_COUPON = "/api/singleUseCoupon";
export const COMPLETE_REGISTER = "/api/patient/complete-register";
export const CREATE_RATING = "/api/itemrating/rating_create";
export const GET_RATING = "/api/itemrating/show";
export const GET_CITY_STATE = "/api/pincode/showCityAndState";
export const GET_ORDER_LIST = "/api/getOrder";
export const GET_PATIENT_DETAILS = "/api/patient/getPateint";
export const GET_PRIMARY_MENU = "/api/primary_menu";
export const GET_PRODUCT_TAGS = "/api/getproducttags";
export const UPDATE_USER_PROFILE = "/api/patient/updateuser";
export const GET_SORT_DATA = "/api/sorting";
export const GET_ORDER_DETAILS = "/api/orderDetail";
export const GET_HOME_PAGE_BANNER =
  "/api/homecollection?action_type=home-slider&slider_for=0&home_type=0";
export const GET_OVERVIEW = "/api/overview";
export const TRACK_ORDER = "/api/trackOrder";
export const SEARCH_DATA = "/api/searchData";
export const GET_VARIATION_IMAGE = "/api/getDetail_variationImage";
export const GET_MY_MEDICINE = "/api/myMedicine";
export const ENQUIRY_DELETE_PROFILE = "/api/enquirty/account_delete_profile";
